<template>
	<div class="dashboard">
		<b-container class="shlajsna">
			<b-row>
				<b-col cols="12">
					<h1 class="text-center">{{ $t("videos.add-video") }}</h1>
				</b-col>
				<b-col cols="12">
					<NavBar></NavBar>
				</b-col>
				<b-col cols="12" class="px-4">
					<b-form @submit="onSubmit" autocomplete="off">
 						<b-form-group label-cols-sm="12" label-cols-md="3" label-cols-lg="4" :label="$t('videos.title')" label-for="title">
            <!-- Naslov je dosta specifičan. Ako je u pitanju latinično pismo na srpskom, onda se pored naslova -->
			      <!-- trebalo da se pojavi ikonica za LAT tag. -->
            <template v-if="$root.lang.IANA === 'sr-Latn' || $root.lang.IANA === 'sr-Cyrl'">
              <b-row>
                <b-col md="11">
                  <b-form-input	id="title" :state="titleState" v-model="media.title" :placeholder="$t('videos.enter-title')" autocomplete="new-password"></b-form-input>
                  <b-form-invalid-feedback>{{	$t("general.enter-valid-title")	}}</b-form-invalid-feedback>
                </b-col>
                <b-col md="1" class="col-form-label">
                  <span id="latIcon" @click="insertLat('title')">LAT</span>
                </b-col>
              </b-row>
            </template>
            <template v-else>
              <b-row>
                <b-col md="12">
                  <b-form-input	id="title" :state="titleState" v-model="media.title" :placeholder="$t('videos.enter-title')" autocomplete="new-password"></b-form-input>
                  <b-form-invalid-feedback>{{	$t("general.enter-valid-title")	}}</b-form-invalid-feedback>
                </b-col>
              </b-row>
            </template>
            </b-form-group>

						<b-form-group label-cols-sm="12" label-cols-md="4" :label="$t('videos.description')" label-for="description" >
							<editor id="description" :init="tinyInitProcessingVideo" v-model="media.description"></editor>
							<b-form-invalid-feedback :state="descriptionState">{{ $t("general.enterDescription") }}</b-form-invalid-feedback>
							<small v-if="$root.lang.IANA === 'sr-Latn'">Napomena: ukoliko unosite reči na stranim jezicima (latiničnim) označite ih i kliknite ikonicu LAT, kako bi se ispravno prikazale na ćiriličnoj verziji.</small>
						</b-form-group>

						<b-form-group label-cols-sm="12" label-cols-md="4" :label="$t('videos.titleEn')" label-for="titleEn">
							<b-form-input id="titleEn" :state="titleEnState" v-model="media.titleEn" :placeholder="$t('videos.enter-title-english')" autocomplete="new-password"></b-form-input>
							<b-form-invalid-feedback :state="titleEnState">{{ $t("general.enterTitleEnError") }}</b-form-invalid-feedback>
						</b-form-group>

						<b-form-group label-cols-sm="12" label-cols-md="4" :label="$t('videos.descriptionEn')" label-for="descriptionEn">
							<editor id="descriptionEn" :init="tinyInitProcessingVideoEn" v-model="media.descriptionEn"></editor>
							<b-form-invalid-feedback :state="descriptionEnState">{{ $t("general.descriptionEnError")}}</b-form-invalid-feedback>
						</b-form-group>

						<b-form-group id="author" label-cols-sm="12" label-cols-md="4" :label="$t('videos.author')" label-for="author">
							<b-form-input :state="authorState" id="author" v-model="media.author" :placeholder="$t('general.enter-author')"></b-form-input>
							<b-form-invalid-feedback>{{	$t("general.enter-valid-author") }}</b-form-invalid-feedback>
						</b-form-group>

						<b-form-group	label-cols-sm="12" label-cols-md="4" :label="$t('videos.year-of-production')" label-for="year">
							<b-form-input id="year" :state="yearState" v-model="media.year" :placeholder="$t('videos.enter-year-of-production')"></b-form-input>
							<b-form-invalid-feedback>{{ $t("general.enter-valid-year") }}</b-form-invalid-feedback>
						</b-form-group>

						<Subtitles :avaiableLanguageList="avaiableLanguageList" :media="media"/>

						<b-form-group label-cols-sm="12" label-cols-md="4" :label="$t('videos.thumbnails')" label-for="thumbnail">
							<b-overlay :show="showOverlay">
								<b-row>
									<b-col md="12">
										<b-overlay>
											<b-row>
												<b-col cols="6" md="4" v-for="image in media.posterImages" :key="image.timeCode">
													<b-img thumbnail fluid :src="image.urlPath" alt="Image 1" class="m-2 posterImages" :data-src="image.urlPath" :data-timecode="image.timeCode" @click="selectThumbnail"></b-img>
												</b-col>
											</b-row>
										</b-overlay>
									</b-col>
								</b-row>
							</b-overlay>
						</b-form-group>

						<b-form-group
							label-cols-sm="12"
							label-cols-md="4"
							:label="$t('general.categories')"
						>
							<TagInput
								id="categories"
								:state="categoriesState"
								:model="selectedCats"
								:options="avaiableCategoryList"
								:type="$t('general.category')"
								@input="selectedCats = $event"
								:error="$t('general.please-select-categories')"
							/>
						</b-form-group>

						<b-form-group
							label-cols-sm="12"
							label-cols-md="4"
							:label="$t('general.languages')"
						>
							<TagInput
								id="langauges"
								:model="selectedLangs"
								:state="languagesState"
								:options="avaiableLanguageList"
								:type="$t('general.language')"
								@input="selectedLangs = $event"
								:error="$t('general.please-select-language')"
							/>
						</b-form-group>

						<b-form-group label-cols-sm="12" label-cols-md="4">
							<p>{{ $t("videos.upload-description") }}</p>
							<i18n path="general.problem" tag="p">
								<a href="mailto:dmn@rtv.rs" target="_blank" rel="noopener"
									>dmn@rtv.rs</a
								>
							</i18n>
						</b-form-group>

						<b-form-group label-cols-sm="12" label-cols-md="4">
							<b-overlay
								:show="saveSpinner"
								spinner-small
								spinner-variant="secondary"
								opacity="0.5"
								class="d-inline-block"
							>
								<b-button
									:disable="saveSpinner"
									type="submit"
									variant="primary"
									>{{ $t("videos.save") }}</b-button
								>
							</b-overlay>
						</b-form-group>
					</b-form>
				</b-col>
			</b-row>
		</b-container>
	</div>
</template>

<script>
import NavBar from "@/components/common/NavBar.vue";
import editor from "@tinymce/tinymce-vue";
import { BImg } from "bootstrap-vue";
import TagInput from "@/components/admin/_utils_/TagInput.vue";
import Subtitles from "@/components/admin/_utils_/Subtitles.vue";

export default {
	name: "ProcessingVideo",
	components: { NavBar, editor, BImg, TagInput, Subtitles },
	data: function() {
		return {
			admin: this.$root.user,
			media: {},
			showOverlay: true,
			myPosterImages: [],
			selectedPoster: {},
			tinyInitProcessingVideo: this.tinyInit(350),
			tinyInitProcessingVideoEn: this.tinyInit(350),
			saveSpinner: false,
			categoryTagObjectsList: [],
			avaiableCategoryList: [],
			selectedCats: [],
			avaiableLanguageList: [
				"Serbian",
				"English",
				"German",
				"Slovak",
				"Hungarian",
				"Bulgarian",
				"Romanian",
				"Ukranian",
				"Moldovan",
				"Rusyn",
				"Roma",
				"Other"
			],
			selectedLangs: [],
			/* State varijable */
			titleState: null,
			descriptionState: null,
			titleEnState: null,
			descriptionEnState: null,
			authorState: null,
			yearState: null,
			categoriesState: null,
			languagesState: null,
			acceptedTermsState: null
		};
	},

	computed: {
		myMedia: function() {
			return this.media;
		},
		_lang: function() {
			return this.$root.lang;
		}
	},

	created: async function() {
		// Get media info
		await this.$http
			.get("/cms/api/media/get/" + this.$route.params.videoId + "/", {
				headers: { Authorization: this.getAuthData().accessToken }
			})
			.then(r => {
				// console.log(response.data);
				this.media = r.data;
				this.media.posterImages = JSON.parse(r.data.posterImages);

				if (r.data.categories) {
					this.selectedCats = this.extractTags(r.data.categories);
				}
				if (r.data.languages) {
					this.selectedLangs = r.data.languages.split(",");
				}
			})
			.catch(e => {
				this.handleError(e);
			});

		this.displayTags();
		this.showOverlay = false;
	},

	methods: {
		selectThumbnail: function(e) {
			var thumbnails = document.getElementsByClassName("posterImages");
			for (var i = 0; i < thumbnails.length; i++) {
				var element = thumbnails[i];
				element.classList.remove("selectedThumbnail");
			}
			e.target.classList.add("selectedThumbnail");
			this.selectedPoster.src = e.target.getAttribute("data-src");
			this.selectedPoster.timeCode = e.target.getAttribute("data-timecode");
		},

		// Start transcode
		startTranscode: function() {
			this.$http
				.post(
					"/cms/api/media/start-transcode/" + this.$route.params.videoId + "/",
					{ headers: { Authorization: this.getAuthData().accessToken } }
				)
				.then(r => {
					this.getTranscodeProgress();
				})
				.catch(e => {
					var message = this.handleError(e);
				});
		},

		onSubmit: function(e) {
			e.preventDefault();
			if (this.validateForm()) {
				this.saveSpinner = true;
				// Ako nije izabrana thumbnail slika, onda postavi prvu sliku za thumbnail.
				if (
					typeof this.selectedPoster.src === "undefined" &&
					typeof this.selectedPoster.timeCode === "undefined"
				) {
					var thumbnails = document.getElementsByClassName("posterImages");
					var element = thumbnails[0];
					this.selectedPoster.src = element.getAttribute("data-src");
					this.selectedPoster.timeCode = element.getAttribute("data-timecode");
				}
				var newData = {};
				newData.id = this.media.id;
				newData.title = this.media.title;
				newData.titleEn = this.media.titleEn;
				newData.description = this.media.description;
				newData.descriptionEn = this.media.descriptionEn;
				newData.author = this.media.author;
				newData.posterUrl = this.selectedPoster.src;
				newData.posterTimeCode = this.selectedPoster.timeCode;
				newData.year = this.media.year;
				newData.categories = this.findRealTags(this.selectedCats);
				// Postavljamo ručno status na nedekodiran video.
				newData.status = 3;
				if (this.selectedLangs.length > 0) {
					newData.languages = this.selectedLangs.sort().join(",");
				}
				// console.log(newData);

				this.$http
					.post("/cms/api/media/save-2/", newData, {
						headers: { Authorization: this.getAuthData().accessToken }
					})
					.then(response => {
						if (typeof response === "object") {
							this.saveSpinner = false;
							this.$router.push({ name: "Dashboard" });
						}
					})
					.catch(error => {
						this.saveSpinner = false;
						this.handleError(error);
					});
			}
		},

		/* Traži u listi tagova one elemente koji se nalaze u listi izabranih kategorija */
		findRealTags(tagList) {
			return this.categoryTagObjectsList.filter(function(item) {
				for (var i = 0; i < item.names.length; i++) {
					if (tagList.includes(item.names[i].name)) {
						return item;
					}
				}
			});
		},

		extractTags(categoryList) {
			var adminLang = this.$root.lang.shortName;
			var myCategoriesSetring = [];
			categoryList.filter(function(item) {
				// console.log(item);
				for (var i = 0; i < item.names.length; i++) {
					if (item.names[i].language.shortName === adminLang) {
						myCategoriesSetring.push(item.names[i].name);
					}
				}
			});
			return myCategoriesSetring;
		},

		validateForm() {
			if (
				typeof this.media.title === "undefined" ||
				this.media.title.length === 0
			) {
				this.titleState = false;
				document
					.getElementById("title")
					.scrollIntoView({ block: "center", behavior: "smooth" });
				return false;
			} else {
				this.titleState = null;
			}

			if (
				typeof this.media.description === "undefined" ||
				this.media.description.length === 0
			) {
				this.descriptionState = false;
				document
					.getElementById("description")
					.nextSibling.classList.add("is-invalid");
				document
					.getElementById("description")
					.parentNode.scrollIntoView({ block: "center", behavior: "smooth" });
				return false;
			} else {
				document
					.getElementById("description")
					.nextSibling.classList.remove("is-invalid");
				this.descriptionState = null;
			}

			if (!this.media.titleEn) {
				this.titleEnState = false;
				document
					.getElementById("titleEn")
					.scrollIntoView({ block: "center", behavior: "smooth" });
				return false;
			} else {
				this.titleEnState = null;
			}

			if (!this.media.descriptionEn) {
				this.descriptionEnState = false;
				document
					.getElementById("descriptionEn")
					.nextSibling.classList.add("is-invalid");
				document
					.getElementById("descriptionEn")
					.parentNode.scrollIntoView({ block: "center", behavior: "smooth" });
				return false;
			} else {
				document
					.getElementById("descriptionEn")
					.nextSibling.classList.remove("is-invalid");
				this.descriptionEnState = null;
			}

			if (
				typeof this.media.author === "undefined" ||
				this.media.author.length === 0
			) {
				this.authorState = false;
				document
					.getElementById("author")
					.scrollIntoView({ block: "center", behavior: "smooth" });
				return false;
			} else {
				this.authorState = null;
			}

			if (typeof this.media.year === "undefined") {
				this.yearState = false;
				document
					.getElementById("year")
					.scrollIntoView({ block: "center", behavior: "smooth" });
				return false;
			} else {
				const year = this.media.year.toString().replace(/[^0-9]+/g, "");
				if (parseInt(year) === "NaN") {
					this.yearState = false;
					document
						.getElementById("year")
						.scrollIntoView({ block: "center", behavior: "smooth" });
					return false;
				}
				this.media.year = year;
				this.yearState = null;
			}

			if (this.selectedCats.length === 0) {
				document
					.getElementById("categories")
					.scrollIntoView({ block: "center", behavior: "smooth" });
				this.categoriesState = false;
				return false;
			} else {
				this.categoriesState = null;
			}

			if (this.selectedLangs === null || this.selectedLangs.length === 0) {
				this.languagesState = false;
				document
					.getElementById("langauges")
					.scrollIntoView({ block: "center", behavior: "smooth" });
				return false;
			} else {
				this.languagesState = null;
			}

			if (this.acceptedTerms === "not_accepted") {
				this.acceptedTermsState = false;
				return false;
			} else {
				this.acceptedTermsState = null;
			}

			return true;

			// return false;
		},

		async displayTags() {
			// Get all tags
			await this.$http
				.get("/cms/api/tag/all-published/" + this.$root.lang.shortName, {
					headers: { Authorization: this.getAuthData().accessToken }
				})
				.then(r => {
					// console.log(r.data);
					//this.getListNames(this.media);
					this.categoryTagObjectsList = r.data;
					if (r.data.length > 0) {
						var myOptions = [];
						for (var i = 0; i < r.data.length; i++) {
							var tag = r.data[i];
							// var names = r.data[i];
							// console.log(tag);
							if (tag.names.length > 0) {
								for (var j = 0; j < tag.names.length; j++) {
									var names = tag.names[j];
									if (names.language.shortName === this.$root.lang.shortName) {
										myOptions.push(names.name);
									}
								}
							}
						}
						this.avaiableCategoryList = myOptions;
					}
				})
				.catch(e => {
					this.handleError(e);
				});
			const myCategories = this.findRealTags(this.selectedCats);
			this.selectedCats = this.extractTags(myCategories);
		}
	},

	watch: {
		_lang: function() {
			// console.log("Promenjen jezik");
			this.displayTags();
		}
	}
};
</script>

<style scoped>
.posterImages {
	cursor: pointer;
	border-width: 3px;
}
.selectedThumbnail {
	border: 3px solid #299eaa;
	box-shadow: 2px 2px 10px 1px #ccc;
}
</style>
